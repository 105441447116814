<template>
  <div
    class="sms-team px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-3">
        <div
          class="fusion-person person fusion-person-left fusion-person-1 fusion-person-icon-top"
        >
          <div class="person-shortcode-image-wrapper">
            <div
              class="person-image-container hover-type-none"
              style="border:0px solid #f6f6f6;-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;"
            >
              <img
                class="person-img img-responsive wp-image-11786"
                width="528"
                height="353"
                src="@/assets/Resources/GDD-min.png"
                alt="Gary Dodds"
              />
            </div>
          </div>
          <div class="person-desc">
            <div class="person-author">
              <div class="person-author-wrapper">
                <span class="person-name">Gary Dodds</span
                ><span class="person-title">President</span>
              </div>
            </div>
            <PanelGroupComponent :panels="[panel1]" />
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-3">
        <div
          class="fusion-column-wrapper"
          style="padding: 0px; background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <div
            class="fusion-person person fusion-person-left fusion-person-2 fusion-person-icon-top"
          >
            <div class="person-shortcode-image-wrapper">
              <div
                class="person-image-container hover-type-none"
                style="border:0px solid #f6f6f6;-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;"
              >
                <img
                  class="person-img img-responsive wp-image-11801"
                  width="528"
                  height="353"
                  src="@/assets/Resources/KNS-min.png"
                  alt="Kirt Sasser, PE"
                />
              </div>
            </div>
            <div class="person-desc">
              <div class="person-author">
                <div class="person-author-wrapper">
                  <span class="person-name">Kirt Sasser, PE</span
                  ><span class="person-title">Vice President</span>
                </div>
              </div>
              <PanelGroupComponent :panels="[panel2]" />
            </div>
          </div>
          <div class="fusion-clearfix"></div>
        </div>
      </div>

      <!-- New Row -->

      <div class="w-full md:w-1/3 mb-6 md:pr-3">
        <div
          class="fusion-person person fusion-person-left fusion-person-3 fusion-person-icon-top"
        >
          <div class="person-shortcode-image-wrapper">
            <div
              class="person-image-container hover-type-none"
              style="border:0px solid #f6f6f6;-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;"
            >
              <img
                class="person-img img-responsive wp-image-11792"
                width="528"
                height="353"
                src="@/assets/Resources/RTF-min.png"
                alt="Robert Ford"
              />
            </div>
          </div>
          <div class="person-desc">
            <div class="person-author">
              <div class="person-author-wrapper">
                <span class="person-name">Robert Ford</span
                ><span class="person-title">Director of Special Projects</span>
              </div>
            </div>
          </div>
        </div>
        <PanelGroupComponent :panels="[panel3]" />
      </div>
      <div class="w-full md:w-1/3 mb-6 md:pr-3">
        <div
          class="fusion-person person fusion-person-left fusion-person-4 fusion-person-icon-top"
        >
          <div class="person-shortcode-image-wrapper">
            <div
              class="person-image-container hover-type-none"
              style="border:0px solid #f6f6f6;-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;"
            >
              <img
                class="person-img img-responsive wp-image-11793"
                width="528"
                height="353"
                src="@/assets/Resources/SEG-min.png"
                alt="Scott Genta"
              />
            </div>
          </div>
          <div class="person-desc">
            <div class="person-author">
              <div class="person-author-wrapper">
                <span class="person-name">Scott Genta</span
                ><span class="person-title"
                  >Director of Arizona Operations</span
                >
              </div>
            </div>
          </div>
        </div>
        <PanelGroupComponent :panels="[panel4]" />
      </div>

      <!-- New Row -->

      <div class="w-full md:w-1/3 mb-6 md:pr-3">
        <div
          class="fusion-person person fusion-person-left fusion-person-5 fusion-person-icon-top"
        >
          <div class="person-shortcode-image-wrapper">
            <div
              class="person-image-container hover-type-none"
              style="border:0px solid #f6f6f6;-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;"
            >
              <img
                class="person-img img-responsive wp-image-11795"
                width="528"
                height="353"
                src="@/assets/Resources/JDH-min.png"
                alt="Jason Howe, PE, CSP"
              />
            </div>
          </div>
          <div class="person-desc">
            <div class="person-author">
              <div class="person-author-wrapper">
                <span class="person-name">Jason Howe, PE, CSP</span
                ><span class="person-title">Director of Risk Management</span>
              </div>
            </div>
          </div>
        </div>
        <PanelGroupComponent :panels="[panel5]" />
      </div>
      <div class="w-full md:w-1/3 mb-6 md:pr-3">
        <div
          class="fusion-person person fusion-person-left fusion-person-6 fusion-person-icon-top"
        >
          <div class="person-shortcode-image-wrapper">
            <div
              class="person-image-container hover-type-none"
              style="border:0px solid #f6f6f6;-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;"
            >
              <img
                class="person-img img-responsive wp-image-11796"
                width="528"
                height="353"
                src="@/assets/Resources/TAG-min.png"
                alt="Troy Gardner, PE, CSP"
              />
            </div>
          </div>
          <div class="person-desc">
            <div class="person-author">
              <div class="person-author-wrapper">
                <span class="person-name">Troy Gardner, PE, CSP</span
                ><span class="person-title"
                  >Testing &amp; Classification Manager</span
                >
              </div>
            </div>
          </div>
        </div>
        <PanelGroupComponent :panels="[panel6]" />
      </div>

      <!-- New Row -->

      <div class="w-full md:w-1/3 mb-6 md:pr-3">
        <div
          class="fusion-column-wrapper"
          style="padding: 0px; background-position: left top; background-repeat: no-repeat; background-size: cover; height: auto;"
          data-bg-url=""
        >
          <div
            class="fusion-person person fusion-person-left fusion-person-8 fusion-person-icon-top"
          >
            <div class="person-shortcode-image-wrapper">
              <div
                class="person-image-container hover-type-none"
                style="border:0px solid #f6f6f6;-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;"
              >
                <img
                  class="person-img img-responsive wp-image-11797"
                  width="528"
                  height="353"
                  src="@/assets/Resources/JST-min.png"
                  alt="Jared Teter, PhD"
                />
              </div>
            </div>
            <div class="person-desc">
              <div class="person-author">
                <div class="person-author-wrapper">
                  <span class="person-name">Jared Teter, PhD</span
                  ><span class="person-title"
                    >Senior Staff Scientist | Operations Manager</span
                  >
                </div>
                <div class="fusion-social-networks">
                  <div class="fusion-social-networks-wrapper">
                    <a
                      class="fusion-social-network-icon fusion-tooltip fusion-facebook fusion-icon-facebook"
                      aria-label="fusion-facebook"
                      href="https://www.facebook.com/jared.teter"
                      target="_blank"
                      rel="noopener noreferrer"
                      style="color:#bebdbd;font-size:16px;"
                      data-placement="top"
                      data-title="Facebook"
                      title=""
                      data-toggle="tooltip"
                      data-original-title="Facebook"
                    ></a
                    ><a
                      class="fusion-social-network-icon fusion-tooltip fusion-mail fusion-icon-mail"
                      aria-label="fusion-mail"
                      href="mailto:jteter@smsenergetics.com"
                      target="_self"
                      style="color:#bebdbd;font-size:16px;"
                      data-placement="top"
                      data-title="Mail"
                      title=""
                      data-toggle="tooltip"
                      data-original-title="Mail"
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="fusion-clearfix"></div>
        </div>
        <PanelGroupComponent :panels="[panel8]" />
      </div>
      <div class="w-full md:w-1/3 mb-6 md:pr-3">
        <div
          class="fusion-person person fusion-person-left fusion-person-9 fusion-person-icon-top"
        >
          <div class="person-shortcode-image-wrapper">
            <div
              class="person-image-container hover-type-none"
              style="border:0px solid #f6f6f6;-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;"
            >
              <img
                class="person-img img-responsive wp-image-11802"
                width="528"
                height="353"
                src="@/assets/Resources/WEP-min.png"
                alt="William Post"
              />
            </div>
          </div>
          <div class="person-desc">
            <div class="person-author">
              <div class="person-author-wrapper">
                <span class="person-name">William Post</span
                ><span class="person-title">PSM/PHA & D3 Manager</span>
              </div>
            </div>
          </div>
        </div>
        <PanelGroupComponent :panels="[panel9]" />
      </div>
      <div class="w-full md:w-1/3 mb-6 md:pr-3">
        <div
          class="fusion-person person fusion-person-left fusion-person-10 fusion-person-icon-top"
        >
          <div class="person-shortcode-image-wrapper">
            <div
              class="person-image-container hover-type-none"
              style="border:0px solid #f6f6f6;-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;"
            >
              <img
                class="person-img img-responsive wp-image-11807"
                width="528"
                height="353"
                src="@/assets/Resources/JLB-min.png"
                alt="Jaye Lynn Boyack"
              />
            </div>
          </div>
          <div class="person-desc">
            <div class="person-author">
              <div class="person-author-wrapper">
                <span class="person-name">Jaye Lynn Boyack</span
                ><span class="person-title"
                  >Sr. Environmental, Health, & Safety Engineer</span
                >
              </div>
            </div>
          </div>
        </div>
        <PanelGroupComponent :panels="[panel10]" />
      </div>

      <!-- New Row -->

      <div class="w-full md:w-1/3 mb-6 md:pr-3">
        <div
          class="fusion-person person fusion-person-left fusion-person-12 fusion-person-icon-top"
        >
          <div class="person-shortcode-image-wrapper">
            <div
              class="person-image-container hover-type-none"
              style="border:0px solid #f6f6f6;-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;"
            >
              <img
                class="person-img img-responsive wp-image-11804"
                width="528"
                height="353"
                src="@/assets/Resources/GJD-min.png"
                alt="Greg Dohm"
              />
            </div>
          </div>
          <div class="person-desc">
            <div class="person-author">
              <div class="person-author-wrapper">
                <span class="person-name">Greg Dohm</span
                ><span class="person-title">Facility Siting Manager</span>
              </div>
            </div>
          </div>
        </div>
        <PanelGroupComponent :panels="[panel12]" />
      </div>
      <div class="w-full md:w-1/3 mb-6 md:pr-3">
        <div
          class="fusion-person person fusion-person-left fusion-person-13 fusion-person-icon-top"
        >
          <div class="person-shortcode-image-wrapper">
            <div
              class="person-image-container hover-type-none"
              style="border:0px solid #f6f6f6;-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;"
            >
              <img
                class="person-img img-responsive wp-image-11805"
                width="528"
                height="353"
                src="@/assets/Resources/JTF-min.png"
                alt="Jason Ford"
              />
            </div>
          </div>
          <div class="person-desc">
            <div class="person-author">
              <div class="person-author-wrapper">
                <span class="person-name">Jason Ford</span
                ><span class="person-title">Test Site Manager</span>
              </div>
            </div>
          </div>
        </div>

        <PanelGroupComponent :panels="[panel13]" />
      </div>
      <!-- New Row -->

      <div class="w-full md:w-1/3 mb-6 md:pr-3">
        <div
          class="fusion-person person fusion-person-left fusion-person-14 fusion-person-icon-top"
        >
          <div class="person-shortcode-image-wrapper">
            <div
              class="person-image-container hover-type-none"
              style="border:0px solid #f6f6f6;-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;"
            >
              <img
                class="person-img img-responsive wp-image-11806"
                width="528"
                height="353"
                src="@/assets/Resources/JDD-min.png"
                alt="Jordan Dzubak"
              />
            </div>
          </div>
          <div class="person-desc">
            <div class="person-author">
              <div class="person-author-wrapper">
                <span class="person-name">Jordan Dzubak</span
                ><span class="person-title">Training Manager</span>
              </div>
              <div class="fusion-social-networks">
                <div class="fusion-social-networks-wrapper">
                  <a
                    class="fusion-social-network-icon fusion-tooltip fusion-mail fusion-icon-mail"
                    aria-label="fusion-mail"
                    href="mailto:jdzubak@smsenergetics.com"
                    target="_self"
                    style="color:#bebdbd;font-size:16px;"
                    data-placement="top"
                    data-title="Mail"
                    title=""
                    data-toggle="tooltip"
                    data-original-title="Mail"
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <PanelGroupComponent :panels="[panel14]" />
      </div>
      <div class="w-full md:w-1/3 mb-6 md:pr-3">
        <div
          class="fusion-person person fusion-person-left fusion-person-15 fusion-person-icon-top"
        >
          <div class="person-shortcode-image-wrapper">
            <div
              class="person-image-container hover-type-none"
              style="border:0px solid #f6f6f6;-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;"
            >
              <img
                class="person-img img-responsive wp-image-11808"
                width="528"
                height="353"
                src="@/assets/Resources/RKC-min.png"
                alt="Rustyn Christensen"
              />
            </div>
          </div>
          <div class="person-desc">
            <div class="person-author">
              <div class="person-author-wrapper">
                <span class="person-name">Rustyn Christensen</span
                ><span class="person-title">Test Equipment Manager</span>
              </div>
            </div>
          </div>
        </div>

        <PanelGroupComponent :panels="[panel15]" />
      </div>

      <!-- New Row -->

      <div class="w-full md:w-1/3 mb-6 md:pr-3">
        <div
          class="fusion-person person fusion-person-left fusion-person-16 fusion-person-icon-top"
        >
          <div class="person-shortcode-image-wrapper">
            <div
              class="person-image-container hover-type-none"
              style="border:0px solid #f6f6f6;-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;"
            >
              <img
                class="person-img img-responsive wp-image-12935"
                width="528"
                height="353"
                src="@/assets/Resources/JDZ-min.png"
                alt="Jack Zarbock"
              />
            </div>
          </div>
          <div class="person-desc">
            <div class="person-author">
              <div class="person-author-wrapper">
                <span class="person-name">Jack Zarbock</span
                ><span class="person-title">Engineer</span>
              </div>
            </div>
          </div>
        </div>
        <PanelGroupComponent :panels="[panel17]" />
      </div>
      <div class="w-full md:w-1/3 mb-6 md:pr-3">
        <div
          class="fusion-person person fusion-person-left fusion-person-16 fusion-person-icon-top"
        >
          <div class="person-shortcode-image-wrapper">
            <div
              class="person-image-container hover-type-none"
              style="border:0px solid #f6f6f6;-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;"
            >
              <img
                class="person-img img-responsive wp-image-12935"
                width="528"
                height="353"
                src="@/assets/Resources/CLB-min.png"
                alt="Collin Boren"
              />
            </div>
          </div>
          <div class="person-desc">
            <div class="person-author">
              <div class="person-author-wrapper">
                <span class="person-name">Collin Boren</span
                ><span class="person-title">Engineer</span>
              </div>
            </div>
          </div>
        </div>
        <PanelGroupComponent :panels="[panel19]" />
      </div>
      <div class="w-full md:w-1/3 mb-6 md:pr-3">
        <div
          class="fusion-person person fusion-person-left fusion-person-16 fusion-person-icon-top"
        >
          <div class="person-shortcode-image-wrapper">
            <div
              class="person-image-container hover-type-none"
              style="border:0px solid #f6f6f6;-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;"
            >
              <img
                class="person-img img-responsive wp-image-12935"
                width="528"
                height="353"
                src="@/assets/Resources/DMS-min.png"
                alt="Derek Sutton"
              />
            </div>
          </div>
          <div class="person-desc">
            <div class="person-author">
              <div class="person-author-wrapper">
                <span class="person-name">Derek Sutton</span
                ><span class="person-title">Engineer</span>
              </div>
            </div>
          </div>
        </div>
        <PanelGroupComponent :panels="[panel20]" />
      </div>
      <div class="w-full md:w-1/3 mb-6 md:pr-3">
        <div
          class="fusion-person person fusion-person-left fusion-person-16 fusion-person-icon-top"
        >
          <div class="person-shortcode-image-wrapper">
            <div
              class="person-image-container hover-type-none"
              style="border:0px solid #f6f6f6;-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;"
            >
              <img
                class="person-img img-responsive wp-image-12935"
                width="528"
                height="353"
                src="@/assets/Resources/CKD-min.png"
                alt="Chandler Davis"
              />
            </div>
          </div>
          <div class="person-desc">
            <div class="person-author">
              <div class="person-author-wrapper">
                <span class="person-name">Chandler Davis</span
                ><span class="person-title">Engineer</span>
              </div>
            </div>
          </div>
        </div>
        <PanelGroupComponent :panels="[panel21]" />
      </div>
      <div class="w-full md:w-1/3 mb-6 md:pl-3">
        <div
          class="fusion-person person fusion-person-left fusion-person-16 fusion-person-icon-top"
        >
          <div class="person-shortcode-image-wrapper">
            <div
              class="person-image-container hover-type-none"
              style="border:0px solid #f6f6f6;-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;"
            >
              <img
                class="person-img img-responsive wp-image-12935"
                width="528"
                height="353"
                src="@/assets/Resources/JAK-min.png"
                alt="Joshua Kneeland"
              />
            </div>
          </div>
          <div class="person-desc">
            <div class="person-author">
              <div class="person-author-wrapper">
                <span class="person-name">Joshua Kneeland</span
                ><span class="person-title">Engineer</span>
              </div>
            </div>
          </div>
        </div>
        <PanelGroupComponent :panels="[panel22]" />
      </div>
      <div class="w-full md:w-1/3 mb-6 md:pl-3">
        <div
          class="fusion-person person fusion-person-left fusion-person-16 fusion-person-icon-top"
        >
          <div class="person-shortcode-image-wrapper">
            <div
              class="person-image-container hover-type-none"
              style="border:0px solid #f6f6f6;-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;"
            >
              <img
                class="person-img img-responsive wp-image-12935"
                width="528"
                height="353"
                src="@/assets/Resources/ELG-min.png"
                alt="Ethan Gustafson"
              />
            </div>
          </div>
          <div class="person-desc">
            <div class="person-author">
              <div class="person-author-wrapper">
                <span class="person-name">Ethan Gustafson</span
                ><span class="person-title">Engineer</span>
              </div>
            </div>
          </div>
        </div>
        <PanelGroupComponent :panels="[panel23]" />
      </div>
      <div class="w-full md:w-1/3 mb-6 md:pl-3">
        <div
          class="fusion-person person fusion-person-left fusion-person-16 fusion-person-icon-top"
        >
          <div class="person-shortcode-image-wrapper">
            <div
              class="person-image-container hover-type-none"
              style="border:0px solid #f6f6f6;-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;"
            >
              <img
                class="person-img img-responsive wp-image-12935"
                width="528"
                height="353"
                src="@/assets/Resources/DSH-min.png"
                alt="Daniel Humble"
              />
            </div>
          </div>
          <div class="person-desc">
            <div class="person-author">
              <div class="person-author-wrapper">
                <span class="person-name">Daniel Humble</span
                ><span class="person-title">Engineer</span>
              </div>
            </div>
          </div>
        </div>
        <PanelGroupComponent :panels="[panel24]" />
      </div>
      <div class="w-full md:w-1/3 mb-6 md:pl-3">
        <div
          class="fusion-person person fusion-person-left fusion-person-16 fusion-person-icon-top"
        >
          <div class="person-shortcode-image-wrapper">
            <div
              class="person-image-container hover-type-none"
              style="border:0px solid #f6f6f6;-webkit-border-radius:0px;-moz-border-radius:0px;border-radius:0px;"
            >
              <img
                class="person-img img-responsive wp-image-12935"
                width="528"
                height="353"
                src="@/assets/Resources/JTB-min.png"
                alt="Jacob Burrell"
              />
            </div>
          </div>
          <div class="person-desc">
            <div class="person-author">
              <div class="person-author-wrapper">
                <span class="person-name">Jacob Burrell</span
                ><span class="person-title">Engineer</span>
              </div>
            </div>
          </div>
        </div>
        <PanelGroupComponent :panels="[panel25]" />
      </div>
    </div>
  </div>
</template>

<script>
import PanelGroupComponent from "@/components/PanelGroupComponent";

export default {
  metaInfo: {
    title: "SMS Team",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS employs 5 DOT approved explosive examiners, as well as many experts in Process hazards Analysis, facility siting, and D3 work."
      }
    ]
  },
  components: {
    PanelGroupComponent
  },
  data: () => {
    return {
      panel1: {
        content1: `Gary Dodds is a Chemical Engineer, Safety Professional, and the President of SMS with expertise in Hazards Analysis, Incident Investigation, Regulatory Compliance, and Explosives Safety for over 30 years. He has specific expertise in the technical safety aspects of explosives manufacturing, handling, transportation, and storage including solid rocket motor manufacturing and testing, propellant manufacturing, munitions manufacturing and demilitarization, commercial explosives manufacturing as well as specialty chemical and oil and gas manufacturing. In addition he has specific expertise in OSHA’s Process Safety Management regulation and DoD, DOE, and US Military explosives safety compliance. Gary has overall responsibility for SMS projects and to ensure technical accuracy, quality of deliverables, and customer satisfaction.`
      },
      panel2: {
        content1: `Mr. Sasser is an experienced executive engineer for Safety Management Services, Inc. with 25 plus years in engineering, energetics testing, and process safety in a variety of industries. He currently serves as the Vice President of SMS as an authorized test and witnessing agent for the U.S. Department of Transportation in the area of explosive classification. In this role, he has performed characterization testing on energetic and pyrotechnic materials and articles in support of DOT (shipping), storage, and in-process classification purposes. Recently, Mr. Sasser has conducted R&D efforts with regards to fireworks projects funded by the U.S. Department of Transportation (PHMSA) including dissection/disassembly and testing of fireworks. He has also directed efforts for classification of potentially damaged fireworks as a result of an accident investigation by the U.S. Chemical Safety Board (CSB). Mr. Sasser has performed a number of facility siting analyses for explosive and pyrotechnic manufacturing and storage facilities throughout the world. Additionally, Mr. Sasser has extensive experience in process safety, performing PHAs and ensuring regulatory compliance for propellant and pyrotechnic manufacturing processes utilizing FMEA, HAZOP, and other risk assessment techniques to identify hazardous conditions.`
      },
      panel3: {
        content1: `Bob founded Safety Management Services, Inc. (SMS). He is a Mechanical Engineering with over 35 years in the explosives industry. SMS is an engineering firm that specializes in performing explosives characterization testing and applying risk management methodology to a wide variety of energetic and hazardous material processes. SMS is also approved by the US Department of Transportation to conduct and witness testing to determine appropriate shipping classifications for explosive substances and articles. Bob serves on the SAAMI delegation to the United Nations Transportation and Dangerous Goods Subcommittee and the Explosives Working Group.`
      },
      panel4: {
        content1: `Scott Genta is a partner with Safety Management Services, Inc. (SMS). He has a degree in Chemical Engineering with over 25 years in the hazardous materials and explosives industries.`
      },
      panel5: {
        content1: `<div class="panel-body toggle-content fusion-clearfix">Mr. Howe is the director of the Hazards Analysis group with a background in propellant combustion. He has applied engineering and risk management protocols while evaluating the risk associated with propellant, explosives, and chemical manufacturing processes. His 20 year experience includes supporting the aerospace, air bag, and chemical industries. He graduated with a B.S. in Chemical Engineering, Brigham Young University. He is also a Professional Engineer and Certified Safety Professional.<br>
Areas of specialization include:<p></p>
<ul>
<li>Hazards Analysis and Risk Assessment of Energetic Material Processing</li>
<li>Qualitative and Quantitative Safety Evaluations of Explosive and Propellant Processes Utilizing Various Hazards Analysis Methodologies</li>
<li>Regulatory Compliance Audits</li>
<li>Industrial Safety Compliance and Requirements</li>
</ul>
</div>`
      },
      /* TODO: Bio here */
      panel6: {
        content1: `<div class="panel-body toggle-content fusion-clearfix">Mr. Gardner is a Professional Engineer and Certified Safety Professional with over 20 years of experience in the examination and evaluation of explosives with extensive experience in explosives testing, hazards analysis and facility siting. He currently serves as SMS’s Testing & Classifications Manager and is an authorized examiner for the U.S. Department of Transportation. Mr. Gardner is a Chemical Engineer and has led SMS’s R&D efforts to evaluate and characterize the energetic properties of thermites and the containment capacity of the DOT-SP 8451 shipping pipe for explosive substances and articles.</div>`
      },
      panel7: {
        content1: `<div class="panel-body toggle-content fusion-clearfix">Clint has a Ph.D. in chemical engineering and a professional engineering (PE) license. Previous to employment for the last 15 years at SMS, he worked at Intel (Fab 12) in Chandler Arizona as a process engineer. He developed GoDetect, the Integrated Violence Model, the SRC method, and other engineering solutions for quantifying energetic reactions and statistically comparing their outcomes. He is active in the Explosives Testing Users Group and also supports the core activities of SMS: risk assessment, testing (DOT and in-process), and test equipment.</div>`
      },
      panel8: {
        content1: `<div class="panel-body toggle-content fusion-clearfix">Dr. Teter is a full-time employee of Safety Management Services, Inc. in the West Jordan, UT office with a background in physics and hazards analysis. As a hazards analyst, he has assisted many clients in applying engineering and risk management protocols to all types of energetic processes. He graduated with a Ph.D. in Physics, University of Utah, M.S., Physics, Purdue University, and B.S., Applied Physics, Brigham Young University.<br>
Areas of specialization include:<p></p>
<ul>
<li>General Explosives Process Design</li>
<li>Hazards Analysis and Risk Assessment of Energetic Material Processing</li>
<li>Management of Safety Programs for Energetic Material and Large Rocket Motor Processing</li>
<li>Process Design and Technical Safety Support for Propellant Manufacturing Processes</li>
<li>Process Design and Technical Safety Support for Large/Small Solid Rocket Motor Manufacturing, Handling, and Testing</li>
<li>Sensitivity Testing of Energetic Materials and Ingredients</li>
</ul>
</div>`
      },
      panel9: {
        content1: `<div class="panel-body toggle-content fusion-clearfix">William Post is a Hazards Analyst and Explosives Safety Specialist with Safety Management Services Inc. (SMS). William is a Chemical Engineer with experience in explosives safety, safety engineering, Process Safety Management (PSM), process engineering, process and equipment design, manufacturing, explosives waste disposal, demilitarization and decommissioning, decontamination, demolition (D3) operations, propellant chemistry, water chemistry, high volume chemical processing, lab scale processing and transitioning of products and energetic materials from R&amp;D to production, continuous improvement efforts, and lean manufacturing practices. William has provided explosive safety support for design, construction and start-up of full scale plants to manufacture single base propellants and PETN. William has led teams to develop and implement OSHA 1910.119 compliant PSM programs for multiple energetic materials manufacturers. He has performed Explosives Safety Reviews and Process Hazards Analysis of many types of processes and equipment related to Energetic Materials Manufacturing. He has participated as the lead or as a key member on multiple pre-startup safety reviews from small scale processes to full manufacturing plants. William has over 25 years of experience in the Hazardous Materials and Explosives industry and he is also experienced in emergency response and HAZWOPER.</div>`
      },
      /* TODO: Bio here */
      panel10: {
        content1: `<div class="panel-body toggle-content fusion-clearfix">Ms. Boyack is a full-time employee of Safety Management Services, Inc. in the Phoenix, AZ office and is a dedicated Environmental, Health & Safety Professional with 25 years of experience in program development, training and international auditing. She has strong organizational, leadership, and communication skills, with proven experience implementing ideas to improve worker safety and health.</div>`
      },
      panel12: {
        content1: `<div class="panel-body toggle-content fusion-clearfix">Mr. Dohm’s 20 years of experience in the aerospace industry has provided him an intimate knowledge of manufacturing, testing and storage functions as applied to propellants, explosives and pyrotechnics (PEP). His strengths include applying technical engineering and safety expertise to real world situations that are frequently unique in order to control risk and achieve results. His experience comprises both supervisory and technical roles in a variety of industries such as PEP manufacturing and processing environmental remediation, demilitarization and testing.</div>`
      },
      /* TODO: Bio Here */
      panel13: {
        content1: `<div class="panel-body toggle-content fusion-clearfix">Jason Ford is a Mechanical Engineer and currently serves as the Test Site Manager for SMS’ explosive test range located at the Tooele Army Depot.  He also manages and is responsible for the test equipment that SMS manufacturers.  Jason is an approved explosives examiner by the U.S. Department of Transportation and has extensive knowledge and testing experience with the classification of pyrotechnics, primary explosives, propellants and articles ranging from small energetic components to large rocket motors.  Jason has been involved with many of SMS’ R&D testing efforts with the DOT-SP 8451 shipping pipe, pyrotechnics, and thermite classification projects.  Jason also has experience in process safety, performing HA’s on many different hazardous process, conducting HAZOPs for the Oil Industry and many other risk assessment techniques to identify and mitigate against hazardous conditions.</div>`
      },
      panel14: {
        content1: `<div class="panel-body toggle-content fusion-clearfix">Jordan Dzubak is a Training Manager and Facility Siting Specialist with Safety Management Services (SMS). Jordan is a chemical engineer with experience in preparing and delivering various aspects of explosives safety and regulatory training, preparing facility site plans, performing air dispersion modeling, completing process hazards analyses, and working with clients to aid in employee safety and regulatory compliance. Jordan has provided his clients with custom training courses tailored to their unique needs and requirements, ensuring that personnel are equipped with the knowledge and skills to perform their work in a safe and efficient manner. He has completed numerous incident investigations and process hazards analyses throughout many explosive manufacturing environments, with particular expertise in the small arms manufacturing sector. Jordan has also prepared explosive site plans for both commercial and government clients and provides regular training in site planning requirements and techniques.</div>`
      },
      panel15: {
        content1: `<div class="panel-body toggle-content fusion-clearfix">Rusty is a manufacturing engineer with experience in product design and fabrication. He is directly involved in the manufacturing and assembly of SMS test equipment, including custom equipment from concept to production. Rusty also supports the testing group in performing characterization testing on energetic and pyrotechnic materials. </div>`
      },
      panel17: {
        content1: `<div class="panel-body toggle-content fusion-clearfix">Jack is a chemical engineer.  His experience includes performing hazards analysis, custom energetics modeling, and BlastX overpressure calculations.   He graduated with a B.S. in chemical engineering from Brigham Young University.</div>`
      },
      panel19: {
        content1: `<div class="panel-body toggle-content fusion-clearfix">Collin has tested and reported on DOT and DoD hazards classification testing, explosive material characterization testing, and process scale-up testing. He is experienced in incorporating safe handling of explosive formulations and articles, evaluating in-process tests and results, and writing test reports. He graduated with a B.S. in chemical engineering from Brigham Young University.</div>`
      },
      panel20: {
        content1: `<div class="panel-body toggle-content fusion-clearfix">Mr. Sutton is a chemical engineer with experience in multiple disciplines including, process hazards analysis, energetics testing, thermite research and D4 operations. His background includes combustion analysis and associated reactor testing. He received a B.S. in chemical engineering from Brigham Young University.</div>`
      },
      panel21: {
        content1: `<div class="panel-body toggle-content fusion-clearfix">Chandler is a hazard analyst with experience in explosives safety and Process Safety Management (PSM). His experience includes utilizing various hazard analysis methods such as Failure Modes and Effects Analysis (FMEA) and Hazard and Operability Study (HAZOP). His analysis work has included melt pour, mixing, pressing, extrusion, and testing operations. He has also led teams to audit PSM programs to ensure compliance with OSHA 29 CFR 1910.119. He graduated with a B.S. in chemical engineering from Brigham Young University.</div>`
      },
      panel22: {
        content1: `<div class="panel-body toggle-content fusion-clearfix">Joshua Kneeland is a full-time employee of Safety Management Services, Inc. in the West Jordan, Utah area. He graduated with a B.S. in Chemical Engineering from Brigham Young University. His background experience includes research in applied electrochemistry involving molten salts, laboratory chemistry, and teaching fluid mechanics.</div>`
      },
      panel23: {
        content1: `<div class="panel-body toggle-content fusion-clearfix">Ethan Gustafson is a full-time employee of Safety Management Services, Inc. in the Phoenix, Arizona area. His background experience includes thermophysical properties research, autoignition temperature testing, and laboratory chemistry. He graduated from Brigham Young University with a B.S. degree in chemical engineering.</div>`
      },
      panel24: {
        content1: `<div class="panel-body toggle-content fusion-clearfix">Dan specializes in machine design and instrumentation used for the characterization of explosive materials. He has experience designing automated equipment for use in a wide range of industries. He is heavily involved in engineering and manufacturing processes. He supports testing, analysis, and reporting for the characterization of explosives. He graduated with a B.S. in Mechanical Engineering from Utah State University.</div>`
      },
      panel25: {
        content1: `<div class="panel-body toggle-content fusion-clearfix">Jacob Burrell is a chemical engineer who graduated from Brigham Young University. His background experience includes food processing and sanitation, machine learning, and laboratory management. He is involved in conducting hazard analyses which include Failure Modes and Effects Analysis (FMEA) and Quantitative Risk Assessments, creating facility siting plans, developing Standard Operating Procedures (SOPs), assisting in UN Classification tests, and manufacturing test equipment.</div>`
      }
    };
  }
};
</script>

<style lang="scss">
.sms-team {
  .fusion-accordian #accordion-2712-1 .panel-title a .fa-fusion-box {
    color: #ffffff;
  }
  .fusion-accordian #accordion-2712-1 .panel-title a .fa-fusion-box:before {
    font-size: 13px;
    width: 13px;
  }
  .fusion-accordian #accordion-2712-1 .fa-fusion-box {
    background-color: #333333;
    border-color: #333333;
  }
  .fusion-accordian #accordion-2712-1 .panel-title a:hover,
  #accordion-2712-1 .fusion-toggle-boxed-mode:hover .panel-title a {
    color: #aa222c;
  }
  .fusion-accordian #accordion-2712-1 .panel-title .active .fa-fusion-box,
  .fusion-accordian #accordion-2712-1 .panel-title a:hover .fa-fusion-box {
    background-color: #aa222c !important;
    border-color: #aa222c !important;
  }

  .person-title,
  .person-name {
    display: block;
    font-size: 13px;
    text-align: left;
    color: black;
  }
}
</style>
